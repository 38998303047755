import React from "react"
import { graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/pro-light-svg-icons/faArrowDown"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Image from "../components/Image"
import Seamless from "../components/SeamlessEmbed"

const SalesPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { heading, youtube, body, form, blurb } = data.allSalesJson.nodes[0]

  return (
    <Layout className="sales-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
        videoIds={[youtube.code]}
      />

      <Section>
        <Container large>
          <div className="sales-page__hero sales-page__even-grid">
            <div className="sales-page__pr--desktop">
              <Text as="h1" text={heading} />
              <Text text={blurb} />
            </div>
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${youtube.code}`}
              controls
              playing
              overVideo
              iconOnly
              language={"en"}>
              <Image publicId={youtube.image} />
            </TheaterVideo>
          </div>
        </Container>
      </Section>

      <div className="sales-page__arrow">
        <FontAwesomeIcon className="bounce" icon={faArrowDown} />
      </div>

      <Section className="sales-page__body">
        <Container large>
          <div className="sales-page__even-grid">
            <div className="sales-page__body-form">
              <Text as="h2" text={form.heading} />
              <Seamless src={form.url} />
            </div>
            <div className="sales-page__body-text sales-page__pr--desktop">
              <Text as="h2" text={body.heading} />
              <Text text={body.text} />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const SalesPageQuery = graphql`
  query SalesQuery($title: String!) {
    allSalesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        youtube {
          code
          image
        }
        form {
          heading
          url
        }
        body {
          heading
          text
        }
      }
    }
  }
`

export default SalesPage
